var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"facecontrol-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"tags":_vm.tariffTag,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),(_vm.tariffTag)?_c('not-available-options-overlay',{attrs:{"tags":_vm.tariffTag,"with-button":false,"description":_vm.$t('brand_tag_help_view_text', {id: _vm.$store.getters.chatId})}}):[_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_facecontrol_bind_channel_title')}},[(_vm.module.model.params.binded_channel_id !== 0)?_c('bind-channel-info',{attrs:{"binded-channel-id":_vm.module.model.params.binded_channel_id},on:{"on-unbind":_vm.unbind}}):[_c('a-alert',[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('facecontrol_bind_channel_mini_help_message', [ !_vm.module.hasLimits() ? _vm.chat.brand.botname : 'ChatKeeperBot']))},slot:"message"})]),_c('bind-channel',{staticClass:"mt-2",attrs:{"binded-channel-id":_vm.module.model.params.binded_channel_id},on:{"on-bind":_vm.bindFaceControlChannel}})]],2),_c('a-card',{staticClass:"mt-5"},[_c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.setRecheckTime,
          'args': {
            'model': _vm.module.model.params,
            'units': [_vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week, _vm.StepUnit.Month],
            'key': 'recheck_interval',
            'hasAnchor': true,
            'settingWrapper': {
              customiseValue: _vm.StepUnit.Hour,
              hasFieldButton: true,
              disabledValue: 0
            }
          }
        }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'notice_before_ban_count',
            'hasAnchor': true,
            'settingWrapper': {
              disabledValue: 0,
              customiseValue: 3,
              hasFieldButton: true,
            }
          }
        }}}),(_vm.module.model.params.notice_before_ban_count > 0)?_c('nested-content',[_c('punish-action-setting',{staticClass:"punish-action-nested",attrs:{"title-key":"warn_action","punish-action-types":_vm.punishActionTypes},model:{value:(_vm.module.model.params.warn_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "warn_action", $$v)},expression:"module.model.params.warn_action"}})],1):_vm._e(),_c('punish-action-setting',{attrs:{"title-key":"ban_action","punish-action-types":_vm.punishActionTypes},model:{value:(_vm.module.model.params.ban_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "ban_action", $$v)},expression:"module.model.params.ban_action"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'journal',
            'prefix': 'message_binded_channel_handler_module_',
            'hasAnchor': true,
          }
        }}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }